import React from 'react';
import Head from 'next/head';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Container, TextField, Typography, IconButton } from '@mui/material';
import { Google as GoogleIcon } from '../icons/google';
import { Logo } from 'src/components/logo';
import { useFirebaseAuth } from 'src/hooks';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import Bugsnag from '@bugsnag/js';
import Fade from '@mui/material/Fade';

const Login = () => {
  const { authUser, signInWithEmailAndPassword, signInWithGoogle, sendPasswordResetEmail } = useFirebaseAuth();
  const router = useRouter();
  const { codigo } = router.query;
  const codigoCadastro = codigo ? `?codigo=${codigo}` : '';
  const [openAlert, setOpenAlert] = React.useState(false);
  const [recoverPassword, setRecoverPassword] = React.useState(false);
  const [recoverPasswordSent, setRecoverPasswordSent] = React.useState(false);

  const formLogin = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Digite um e-mail valido').max(255).required('E-mail requerido'),
      password: Yup.string().max(255).required('Senha requerida'),
    }),
    onSubmit: ({ email, password }, { resetForm, setSubmitting }) => {
      signInWithEmailAndPassword(email, password)
        .then(() => setSubmitting(false))
        .catch((error) => {
          resetForm({
            values: {
              email: email,
              password: '',
            },
          });
          Bugsnag.notify(error);
          setOpenAlert(true);
        });
    },
  });

  const formRecover = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Digite um e-mail valido').max(255).required('E-mail requerido'),
    }),
    onSubmit: ({ email }, { setSubmitting }) => {
      sendPasswordResetEmail(email)
        .then(() => {
          setRecoverPasswordSent(true);
          setSubmitting(false);
        })
        .catch((error) => {
          Bugsnag.notify(error);
          setOpenAlert(true);
        });
    },
  });

  React.useEffect(() => {
    if (authUser) {
      const { codigo } = router.query;
      if (codigo) {
        router.push(`/router/account/producer/accountproducer${codigoCadastro}`);
        return;
      }

      router.push('/');
    }
  }, [authUser, codigoCadastro, router]);

  const closeAlert = () => {
    setOpenAlert(false);
  };

  const forgotPassword = () => setRecoverPassword((p) => !p);

  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <Box
        component='main'
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minHeight: '100%',
        }}
      >
        <Container maxWidth='sm'>
          {!recoverPassword ? (
            <form onSubmit={formLogin.handleSubmit}>
              <Box
                sx={{
                  pb: 1,
                  pt: 3,
                  textAlign: 'center',
                }}
              >
                <Logo
                  sx={{
                    height: 54,
                    width: 54,
                  }}
                />
              </Box>
              <Box className='borda radius' sx={{ p: 4 }}>
                <Typography variant='p'>Usuário</Typography>
                <TextField
                  error={Boolean(formLogin.touched.email && formLogin.errors.email)}
                  fullWidth
                  helperText={formLogin.touched.email && formLogin.errors.email}
                  label='Digite seu usuário'
                  margin='normal'
                  name='email'
                  onBlur={formLogin.handleBlur}
                  onChange={formLogin.handleChange}
                  type='email'
                  value={formLogin.values.email}
                  variant='outlined'
                />
                <Typography variant='p'>Senha</Typography>
                <TextField
                  error={Boolean(formLogin.touched.password && formLogin.errors.password)}
                  fullWidth
                  helperText={formLogin.touched.password && formLogin.errors.password}
                  label='Digite sua senha'
                  margin='normal'
                  name='password'
                  onBlur={formLogin.handleBlur}
                  onChange={formLogin.handleChange}
                  type='password'
                  value={formLogin.values.password}
                  variant='outlined'
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color='primary'
                    disabled={formLogin.isSubmitting}
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                  >
                    Fazer login
                  </Button>
                  <NextLink href={`/router/account/personal/accountpersonal${codigoCadastro}`}>
                    <Button
                      color='primary'
                      disabled={formLogin.isSubmitting}
                      fullWidth
                      size='large'
                      variant='outlined'
                      sx={{ mt: '16px' }}
                    >
                      Cadastre-se
                    </Button>
                  </NextLink>
                </Box>
                <Box sx={{ py: 2 }}>
                  <Button
                    onClick={forgotPassword}
                    color='primary'
                    disabled={formLogin.isSubmitting}
                    fullWidth
                    size='large'
                    variant='text'
                  >
                    Esqueci minha senha
                  </Button>
                </Box>
                <Box>
                  <Button
                    color='error'
                    startIcon={<GoogleIcon />}
                    onClick={signInWithGoogle}
                    size='small'
                    variant='text'
                  >
                    Login with Google
                  </Button>
                </Box>
              </Box>
            </form>
          ) : (
            <form onSubmit={formRecover.handleSubmit}>
              <Box
                sx={{
                  pb: 1,
                  pt: 3,
                  textAlign: 'center',
                }}
              >
                <Logo
                  sx={{
                    height: 54,
                    width: 54,
                  }}
                />
              </Box>
              <Box className='borda radius' sx={{ p: 4 }}>
                <Typography variant='p'>E-mail</Typography>
                <TextField
                  error={Boolean(formRecover.touched.email && formRecover.errors.email)}
                  fullWidth
                  helperText={formRecover.touched.email && formRecover.errors.email}
                  label='Digite seu e-mail'
                  margin='normal'
                  name='email'
                  onBlur={formRecover.handleBlur}
                  onChange={formRecover.handleChange}
                  type='email'
                  value={formRecover.values.email}
                  variant='outlined'
                />
                <Fade in={recoverPasswordSent} timeout={1000}>
                  <Typography color={'error'}>Verifique seu email com o codigo e link para trocar sua senha</Typography>
                </Fade>
                <Box sx={{ py: 2 }}>
                  <Button
                    type='submit'
                    color='primary'
                    disabled={formRecover.isSubmitting}
                    fullWidth
                    size='large'
                    variant='contained'
                  >
                    Recuperar minha senha
                  </Button>
                  <Button
                    onClick={forgotPassword}
                    color='primary'
                    disabled={formRecover.isSubmitting}
                    fullWidth
                    size='large'
                    variant='outlined'
                    sx={{ mt: '16px' }}
                  >
                    Voltar
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Container>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Collapse in={openAlert}>
          <Alert
            severity='error'
            action={
              <IconButton aria-label='close' color='inherit' size='small' onClick={closeAlert}>
                <CloseIcon fontSize='inherit' />
              </IconButton>
            }
          >
            <AlertTitle>Erro ao logar</AlertTitle>
            Usuário ou senha invalido — <strong>confirme as informações.</strong>
          </Alert>
        </Collapse>
      </Box>
    </>
  );
};

export default Login;
